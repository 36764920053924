import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { Channel } from "@/types/uzs/settings/channel/channel";

export default class ChannelApiService {
    public async delete(id: number): Promise<SuccessResult> {
        const response = await axios.delete(config.backend + "/api/settings/channel/" + id);

        return response?.data as SuccessResult;
    }

    public async fetchAll(params: RequestParameters): Promise<DataResult<Channel[] | null>> {
        const response = await axios.get(config.backend + "/api/settings/channels", {
            params,
        });

        return response?.data as DataResult<Channel[] | null>;
    }

    public async store(data: Channel): Promise<SuccessResult> {
        const response = await axios.post(config.backend + "/api/settings/channel", data);

        return response?.data as SuccessResult;
    }

    public async updateYear(year: number): Promise<SuccessResult> {
        const response = await axios.post(config.backend + "/api/settings/channel/update-year", {
            year,
        });

        return response?.data as SuccessResult;
    }
}
