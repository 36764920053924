<template>
    <target-group-dialog
        v-if="this.showTargetGroupDialog"
        v-model="this.showTargetGroupDialog"
        :target-group="this.currentTargetGroup"
        @input="this.targetGroupDialogInput"
    />

    <update-year-dialog v-model="this.showUpdateYearDialog" @input="this.updateYearDialogInput" />

    <el-form class="table-actions">
        <el-button @click="this.newTargetGroup">Nieuwe doelgroep toevoegen</el-button>
        <el-button @click="this.updateYear">Jaar instellen</el-button>
    </el-form>

    <el-table
        v-loading="this.loading"
        :default-sort="{ prop: 'name', order: 'ascending' }"
        :data="this.targetGroups?.data"
        :row-class-name="this.getRowClassName"
        @sort-change="this.sortingChanged($event)"
        width="100%"
    >
        <el-table-column
            class-name="column-target-group-tip"
            label="Doelgroep (TIP / import)"
            min-width="175"
            prop="imported_name"
            sortable="custom"
        >
            <template #default="scope">
                {{ scope.row.imported_name }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-name"
            label="Doelgroep"
            min-width="175"
            prop="name"
            sortable="custom"
        >
            <template #default="scope">
                {{ scope.row.name }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-country"
            label="Land"
            min-width="135"
            prop="country"
            sortable="custom"
        >
            <template #default="scope">
                {{ scope.row.country }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-size"
            label="Grootte"
            min-width="135"
            prop="size"
            sortable="custom"
        >
            <template #default="scope">
                <span :title="scope.row.year > 0 ? 'Jaar: ' + scope.row.year : ''">
                    {{ scope.row.size }}
                </span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-is-most-important"
            label="Belangrijkste doelgroep"
            min-width="65"
            prop="is_most_important"
            sortable="custom"
        >
            <template #default="scope">
                {{ scope.row.is_most_important ? "Ja" : "Nee" }}
            </template>
        </el-table-column>
        <el-table-column class-name="column-actions" fixed="right" min-width="120">
            <template #default="scope">
                <el-button
                    icon="el-icon-edit"
                    plain="plain"
                    size="mini"
                    title="Bekijk details"
                    @click="this.openTargetGroupDialog(scope.row)"
                ></el-button>
                <el-button
                    icon="el-icon-delete"
                    plain="plain"
                    size="mini"
                    title="Verwijder"
                    type="danger"
                    @click="this.delete(scope)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="this.targetGroups?.pagination?.total_items"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, Ref, ref } from "vue";

import TargetGroupApiService from "@/service/api/settings/target-group/target-group";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { TargetGroup } from "@/types/uzs/settings/target-group/target-group";
import TargetGroupDialog from "@/views/settings/target-groups/dialogs/TargetGroup.vue";
import UpdateYearDialog from "@/views/settings/target-groups/dialogs/UpdateYear.vue";

type CurrentRow = {
    row: TargetGroup;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { UpdateYearDialog, TargetGroupDialog },
    setup() {
        const targetGroupApiService = new TargetGroupApiService();
        const currentTargetGroup = ref(null) as Ref<TargetGroup | null>;
        const textCopier = new Copy();

        return {
            currentTargetGroup,
            targetGroupApiService,
            textCopier,
        };
    },
    computed: {
        page(): number {
            return this.pagination.page as number;
        },
        pages(): number {
            return this.targetGroups?.pagination?.total_pages ?? 1;
        },
        perPage(): number {
            return this.pagination.per_page as number;
        },
    },
    data() {
        return {
            filter: {} as FilterRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                name: "asc",
            } as OrderRequest,
            currentPage: undefined as number | undefined,
            loading: true,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            showFilterModal: false,
            showTargetGroupDialog: false,
            showUpdateYearDialog: false,
            sortingEnabled: false,
            targetGroups: {} as DataResult<TargetGroup[] | null>,
        };
    },
    mounted() {
        this.update();
    },
    methods: {
        delete(value: CurrentRow) {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                this.targetGroupApiService
                    .delete(value.row.id)
                    .then((result) => {
                        this.update();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        },
        getRowClassName(value: CurrentRow) {
            return "row " + value.row.id;
        },
        goToPage(page: number) {
            this.currentPage = page;
            this.pagination.page = page;

            this.update();
        },
        newTargetGroup() {
            this.currentTargetGroup = {} as TargetGroup;
            this.showTargetGroupDialog = true;
        },
        updateYear() {
            this.showUpdateYearDialog = true;
        },
        pageSizeChange(pageSize: number) {
            this.pagination.per_page = pageSize;

            this.update();
        },
        routeToDetails($event: Event, row: TargetGroup) {
            $event.stopImmediatePropagation();

            this.$router.push({
                name: "target-group-detail",
                params: {
                    id: row.id,
                },
            });
        },
        sortingChanged($event: SortEvent) {
            const property = $event.prop?.length ? $event.prop : null;
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!property || !this.targetGroups || !this.sortingEnabled) {
                return;
            }

            this.order = {
                [property]: direction,
            };

            this.update();
        },
        openTargetGroupDialog(targetGroup?: TargetGroup | null) {
            this.currentTargetGroup = targetGroup ?? null;
            this.showTargetGroupDialog = true;
        },
        targetGroupDialogInput(value: boolean) {
            this.showTargetGroupDialog = value;
            this.update();
        },
        updateYearDialogInput(value: boolean) {
            this.showUpdateYearDialog = value;
            this.update();
        },
        update() {
            this.loading = true;
            this.targetGroupApiService
                .fetchAll({
                    filter: this.filter,
                    pagination: this.pagination,
                    sort: this.order,
                })
                .then((response) => {
                    this.loading = false;
                    this.sortingEnabled = true;
                    this.targetGroups = response;
                });
        },
    },
});
</script>
