<template>
    <el-table
        v-loading="this.isLoading"
        :default-sort="{ prop: 'export_start', order: 'descending' }"
        :data="this.exports"
        width="100%"
    >
        <el-table-column
            class-name="column-advertiser"
            label="Adverteerder"
            min-width="100"
            prop="advertiser"
        >
            <template #default="scope">
                <span :title="scope.row?.advertiser">{{ scope.row?.advertiser }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-campaign"
            label="Campagne"
            min-width="100"
            prop="campaign"
        >
            <template #default="scope">
                <span :title="scope.row.campaign?.name">{{ scope.row.campaign?.name }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-date"
            label="Looptijd"
            min-width="100"
            prop="start_date"
        >
            <template #default="scope">
                <span :title="this.getDatePeriod(scope.row)">{{
                    this.getDatePeriod(scope.row)
                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-filtered"
            label="Gefilterd"
            min-width="100"
            prop="filtered"
        >
            <template #default="scope">
                <span>{{ scope.row.export_filtered_data ? 'Ja' : 'Nee' }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-export"
            label="Geëxporteerd"
            min-width="130"
            prop="export_start"
        >
            <template #default="scope">
                <span :title="this.dateHelper.dateTime(scope.row.export_start)">{{
                    this.dateHelper.dateTime(scope.row.export_start)
                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-export-end"
            label="Verwerkt"
            min-width="130"
            prop="export_end"
        >
            <template #default="scope">
                <span
                    v-if="scope.row.export_end"
                    :title="this.dateHelper.dateTime(scope.row.export_end)"
                    >{{ this.dateHelper.dateTime(scope.row.export_end) }}</span
                >
                <el-icon v-if="scope.row.export_start && !scope.row.export_end" name="loading" />
            </template>
        </el-table-column>
        <el-table-column class-name="column-actions keep-width" width="230" fixed="right">
            <template #default="scope">
                <el-button
                    v-if="scope.row.export_end"
                    icon="el-icon-download"
                    plain="plain"
                    size="mini"
                    title="Download"
                    @click="this.onDownloadExport(scope.row)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pageCount"
        :page-size="this.itemsPerPage"
        :page-sizes="this.pageSizes"
        :total="this.itemCount"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.onPageSizeChanged"
    />
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { Store, useStore } from "vuex";

import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RootState } from "@/types/state/root";
import { ImportedBlocks } from "@/types/uzs/importedBlocks";
import ExportApiService from "@/service/api/integration/bigquery/exports";
import { Export } from "@/types/uzs/bigquery/export";
import { format } from "date-fns";
import { nl } from "date-fns/locale";

export default defineComponent({
    name: "List",
    setup() {
        const store = useStore() as Store<RootState>;
        const rootState = store.state as RootState;
        const auth = rootState.authentication as Authentication;
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const exportApi = new ExportApiService();

        const actions = {
            exports: {
                list: () => exportApi.list(),
                download: (uuid: string) => exportApi.download(uuid),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            backendUrl: config.backend,
            isLoading: false,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            currentPage: 1,
            currentBlocks: null as ImportedBlocks | null,
            pageCount: 0,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            itemsPerPage: config.pagination.per_page,
            itemCount: 0,
            exports: [] as Export[],
            showTemplateDialog: false,
            showImportedBlocksDialog: false,
            showSelectedBlocksDialog: false,
            selectedBlocksGuid: null as string | null,
            updateTimeout: null as any | null,
        });

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            loadExports();
        };

        const init = (): void => {
            loadExports();
            updateOnInterval();
        };

        const loadExports = (isLoading = true): void => {
            data.isLoading = isLoading;

            actions.exports
                .list()
                .then((result) => {
                    data.currentPage = result.pagination?.page ?? 1;
                    data.pageCount = result.pagination?.total_pages ?? 0;
                    data.itemsPerPage = result.pagination?.per_page ?? config.pagination.per_page;
                    data.itemCount = result.pagination?.total_items ?? 0;

                    return (result.data ?? []) as Export[];
                })
                .then((response) => {
                    data.exports = response;
                    data.isLoading = false;
                });
        };

        async function onDownloadExport(row: Export) {
            const target = document.createElement("a");
            const objectUrl = await actions.exports.download(row.uuid);

            target.href = objectUrl;
            target.download = "sessions.csv";
            target.click();

            URL.revokeObjectURL(objectUrl);
        }

        const onPageSizeChanged = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            loadExports();
        };

        const updateOnInterval = () => {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }

            if (!data.isLoading) {
                loadExports(false);
            }

            data.updateTimeout = setTimeout(() => {
                updateOnInterval();
            }, 3000);
        };

        const getDatePeriod = (value: Export) => {
            const startDate = DateHelper.date(value.start_date ?? "now");
            const endDate = DateHelper.date(value.end_date);

            let startDay = format(startDate, "d");
            const endDay = format(endDate, "d");

            let startMonth =
                " " + format(startDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

            const endMonth =
                " " + format(endDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

            let startYear = " " + format(startDate, "yyyy");
            const endYear = " " + format(endDate, "yyyy");

            if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
                startDay = "";
            }

            if (startMonth === endMonth) {
                startMonth = "";
            }

            if (startYear === endYear) {
                startYear = "";
            }

            return (
                startDay +
                startMonth +
                startYear +
                (startDay === "" && startMonth === "" && startYear === "" ? "" : " - ") +
                endDay +
                endMonth +
                endYear
            )};

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }
        });

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            accessToken: auth.token,
            dateHelper,
            getDatePeriod,
            goToPage,
            grpHelper,
            onDownloadExport,
            onPageSizeChanged,
        };
    },
});
</script>

<style lang="scss">
@media screen and (max-width: 719px) {
    .column-actions button.edit,
    .column-actions button.delete {
        display: none;
    }
}
</style>
