<template>
    <template v-if="this.showChannelModal">
        <channel-dialog
            v-model="this.showChannelModal"
            :channel="this.currentChannel"
            @input="this.channelInput"
        />
    </template>

    <update-year-dialog v-model="this.showUpdateYearModal" @input="this.updateYearInput" />

    <el-form class="table-actions">
        <el-button @click="this.newChannel">Nieuwe zender toevoegen</el-button>
        <el-button @click="this.updateYear">Jaar instellen</el-button>

        <el-select
            v-model="this.selectedChannelClusters"
            class="channel-cluster-select"
            placeholder="Channel clusters"
            :multiple="true"
            @change="this.update"
        >
            <el-option
                v-for="channelCluster of this.channelClusters"
                :key="channelCluster"
                :value="channelCluster"
                :label="channelCluster"
                >{{ channelCluster }}</el-option
            >
        </el-select>
        <el-select
            v-model="this.selectedOperators"
            class="channel-operator-select"
            placeholder="Exploitant"
            :multiple="true"
            @change="this.update"
        >
            <el-option
                v-for="operator of this.operators"
                :key="operator"
                :value="operator"
                :label="operator"
                >{{ operator }}</el-option
            >
        </el-select>
        <el-select
            v-model="this.selectedCountries"
            class="country-select"
            placeholder="Landen"
            :multiple="true"
            @change="this.update"
        >
            <el-option
                v-for="country of this.countries"
                :key="country"
                :value="country"
                :label="country"
                >{{ country }}</el-option
            >
        </el-select>
    </el-form>

    <el-table
        v-loading="this.loading"
        :default-sort="{ prop: 'country', order: 'ascending' }"
        :data="this.getChannelData()"
        :row-class-name="this.getRowClassName"
        @sort-change="this.sortingChanged($event)"
        width="100%"
    >
        <el-table-column
            class-name="column-channel-tip"
            label="Zender (TIP / import)"
            min-width="175"
            prop="description"
            sortable
        >
            <template #default="scope">
                {{ scope.row.description ?? "" }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-channel"
            label="Zender"
            min-width="175"
            prop="name"
            sortable
        >
            <template #default="scope">
                {{ scope.row.name ?? "" }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-country"
            label="Land"
            min-width="135"
            prop="country"
            sortable="custom"
        >
            <template #default="scope">
                {{ scope.row.country }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-cluster"
            label="Channel cluster"
            min-width="175"
            prop="cluster"
            sortable="custom"
        >
            <template #default="scope">{{ scope.row.cluster }}</template>
        </el-table-column>
        <el-table-column class-name="column-actions" fixed="right" min-width="120">
            <template #default="scope">
                <el-button
                    icon="el-icon-edit"
                    plain="plain"
                    size="mini"
                    title="Bekijk details"
                    @click="this.editChannel(scope.row)"
                ></el-button>
                <el-button
                    icon="el-icon-delete"
                    plain="plain"
                    size="mini"
                    title="Verwijder"
                    type="danger"
                    @click="this.delete(scope)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="this.channels?.pagination?.total_items"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, Ref, ref } from "vue";

import { ChannelCluster } from "@/enums/channelCluster";
import { Country } from "@/enums/country";
import ChannelApiService from "@/service/api/settings/channel/channel";
import SubOrderApiService from "@/service/api/subOrder";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { Channel } from "@/types/uzs/settings/channel/channel";
import ChannelDialog from "@/views/settings/channels/dialogs/Channel.vue";
import UpdateYearDialog from "@/views/settings/channels/dialogs/UpdateYear.vue";

type CurrentRow = {
    row: Channel;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { UpdateYearDialog, ChannelDialog },
    setup() {
        const channelApi = new ChannelApiService();
        const channelClusters = ChannelCluster;
        const countries = Country;
        const currentChannel = ref(null) as Ref<Channel | null>;
        const textCopier = new Copy();

        return {
            channelApi,
            channelClusters,
            countries,
            currentChannel,
            textCopier,
        };
    },
    computed: {
        page(): number {
            return this.pagination.page;
        },
        pages(): number {
            return this.channels?.pagination?.total_pages as number;
        },
        perPage(): number {
            return this.pagination.per_page;
        },
    },
    data() {
        return {
            channels: {} as DataResult<Channel[] | null>,
            selectedChannelClusters: [] as Array<ChannelCluster>,
            selectedCountries: [] as Array<Country>,
            selectedOperators: [] as string[],
            filter: {} as FilterRequest,
            order: {
                country: "asc",
            } as OrderRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            currentPage: undefined as number | undefined,
            loading: true,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            showChannelModal: false,
            showUpdateYearModal: false,
            showFilterModal: false,
            sortingEnabled: false,
            operators: [] as string[],
        };
    },
    mounted() {
        this.update();
        this.loadOperators();
    },
    methods: {
        channelInput(value: boolean) {
            this.currentChannel = null;
            this.showChannelModal = value;
            this.update();
        },
        updateYearInput(value: boolean) {
            this.showUpdateYearModal = value;
            this.update();
        },
        delete(value: CurrentRow) {
            const name = value?.row?.name?.length ? value.row.name : value.row.description;

            ElMessageBox.confirm(
                "Weet je zeker dat je " + name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                this.channelApi
                    .delete(value.row.id)
                    .then((result) => {
                        this.update();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        },
        editChannel(channel: Channel) {
            this.currentChannel = channel;
            this.showChannelModal = true;
        },
        getChannelData(): Channel[] {
            return (
                this.channels.data?.filter((data) => data.name !== "" && data.name !== null) ?? []
            );
        },
        loadOperators() {
            const subOrderApi = new SubOrderApiService();

            subOrderApi.fetchOperators().then((data) => {
                this.operators = data.data ?? [];
            });
        },
        getRowClassName(value: CurrentRow) {
            return "row " + value.row.id;
        },
        goToPage(page: number) {
            this.currentPage = page;
            this.pagination.page = page;

            this.update();
        },
        newChannel() {
            this.showChannelModal = false;
            this.currentChannel = null;
            this.showChannelModal = true;
        },
        updateYear() {
            this.showUpdateYearModal = false;
            this.showUpdateYearModal = true;
        },
        pageSizeChange(pageSize: number) {
            this.pagination.per_page = pageSize;

            this.update();
        },
        routeToDetails($event: Event, row: Channel) {
            $event.stopImmediatePropagation();

            this.$router.push({
                name: "channel-detail",
                params: {
                    id: row.id,
                },
            });
        },
        sortingChanged($event: SortEvent) {
            const property = $event.prop?.length ? $event.prop : null;
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!property || !this.channels || !this.sortingEnabled) {
                return;
            }

            this.order = {
                [property]: direction,
            };

            this.update();
        },
        update() {
            this.loading = true;

            Object.assign(this.filter, {
                cluster: this.selectedChannelClusters,
            });

            Object.assign(this.filter, {
                country: this.selectedCountries,
            });

            Object.assign(this.filter, {
                operator: this.selectedOperators,
            });

            this.channelApi
                .fetchAll({
                    filter: this.filter,
                    sort: this.order,
                    pagination: this.pagination,
                })
                .then((response) => {
                    this.loading = false;
                    this.sortingEnabled = true;
                    this.channels = response;
                });
        },
    },
});
</script>
