import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { ErrorResult } from "@/types/result/errorResult";
import { SuccessResult } from "@/types/result/successResult";
import { DataSet } from "@/types/uzs/settings/bigquery/dataSet";

export default class DataSetApiService {
    public async list(params: RequestParameters): Promise<DataResult<DataSet[]>> {
        const response = await axios.get(config.backend + "/api/settings/bigquery/datasets", {
            params,
        });

        return response?.data as DataResult<DataSet[]>;
    }

    public async update(data: DataSet): Promise<DataResult<DataSet>> {
        const response = await axios.post(
            config.backend + "/api/settings/bigquery/dataset/" + data.guid,
            data
        );

        return response?.data as DataResult<DataSet>;
    }

    public async updateCampaign(
        campaignId: number,
        path: string,
        gaEvents: string,
        includeHomepageInPaths: boolean
    ): Promise<SuccessResult | ErrorResult> {
        const response = await axios.post(
            config.backend + "/api/settings/bigquery/dataset/campaign/" + campaignId,
            {
                path,
                ga_events: gaEvents,
                include_homepage_in_paths: includeHomepageInPaths,
            }
        );

        return response?.data as SuccessResult | ErrorResult;
    }
}
