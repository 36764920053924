import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { TargetGroup } from "@/types/uzs/settings/target-group/target-group";

export default class TargetGroupApiService {
    public async delete(id: number): Promise<SuccessResult> {
        const response = await axios.delete(config.backend + "/api/settings/target-group/" + id);

        return response?.data as SuccessResult;
    }

    public async fetchAll(params: RequestParameters): Promise<DataResult<TargetGroup[] | null>> {
        const response = await axios.get(config.backend + "/api/settings/target-groups", {
            params,
        });

        return response?.data as DataResult<TargetGroup[] | null>;
    }

    public async store(data: TargetGroup): Promise<SuccessResult> {
        const response = await axios.post(config.backend + "/api/settings/target-group", data);

        return response?.data as SuccessResult;
    }

    public async updateYear(year: number): Promise<SuccessResult> {
        const response = await axios.post(
            config.backend + "/api/settings/target-group/update-year",
            {
                year,
            }
        );

        return response?.data as SuccessResult;
    }
}
