export default class PercentageHelper {
    static display(
        value: number | null | undefined,
        round = false,
        allowAboveHundred = false,
        showSign = true
    ): string {
        if (value === null || value === undefined) {
            return "? %";
        }

        if (!allowAboveHundred && value > 100) {
            value = 100;
        }

        if (value < 0) {
            value = 0;
        }

        let parsedValue = Math.round(value * 10) / 10;

        if (round) {
            parsedValue = Math.round(parsedValue);
        }

        let percentage = parsedValue.toString().replace(".", ",");

        if (percentage.indexOf(",") === -1 && value !== 100 && !round) {
            percentage += ",0";
        }

        return percentage + (showSign ? "%" : "");
    }
}
