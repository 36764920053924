
import { ElMessage } from "element-plus";
import { range } from "lodash";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";

import { Country } from "@/enums/country";
import TargetGroupApiService from "@/service/api/settings/target-group/target-group";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { TargetGroup as ScreenForceTargetGroup } from "@/types/screen-force/targetGroup";
import { TargetGroup } from "@/types/uzs/settings/target-group/target-group";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    targetGroup: {
        type: Object as PropType<TargetGroup>,
        required: true,
    },
};

export default defineComponent({
    name: "TargetGroupDialog",
    props,
    setup(props, { emit }) {
        const store = useStore();
        const targetGroupApiService = new TargetGroupApiService();
        const targetGroup = ref(props.targetGroup) as Ref<TargetGroup | undefined>;
        const years = range(2017, new Date().getFullYear() + 2);

        const actions = {
            targetGroup: {
                list: (params: RequestParameters) => targetGroupApiService.fetchAll(params),
                listScreenForce: (params?: RequestParameters) =>
                    store.dispatch("targetGroup/_list_screen_force", params),
                store: (data: TargetGroup) => targetGroupApiService.store(data),
            },
        };

        const data = reactive({
            countries: Country,
            allTargetGroups: [] as TargetGroup[],
            usedScreenForceTargetGroups: [] as number[],
            screenForceTargetGroups: [] as ScreenForceTargetGroup[],
            isLoadingTargetGroups: false,
        });

        const init = (): void => {
            if (targetGroup.value && !targetGroup.value?.country) {
                targetGroup.value.country = Country.NETHERLANDS;
            }

            loadTargetGroups();
        };

        function addSize() {
            if (!targetGroup.value) {
                return;
            }

            if (!targetGroup.value?.sizes || targetGroup.value.sizes.length === 0) {
                targetGroup.value.sizes = [];
                targetGroup.value.sizes.push({
                    target_group_reference: targetGroup.value.id.toString(),
                    year: new Date().getFullYear(),
                    size: 0,
                });
                return;
            }

            targetGroup.value.sizes.push({
                target_group_reference: targetGroup.value.id.toString(),
                year: new Date().getFullYear(),
                size: 0,
            });
        }

        function deleteSize(index: number) {
            if (!targetGroup.value?.sizes?.length) {
                return;
            }

            targetGroup.value.sizes = targetGroup.value.sizes.filter((_, i) => i !== index);
        }

        const close = (): void => {
            targetGroup.value = {} as TargetGroup;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const loadTargetGroups = (): void => {
            data.isLoadingTargetGroups = true;

            actions.targetGroup
                .list({
                    pagination: {
                        page: 1,
                        per_page: 1000,
                    },
                })
                .then((response) => {
                    data.allTargetGroups = response.data ?? [];

                    data.usedScreenForceTargetGroups = data.allTargetGroups.map((result) => {
                        return result.id !== targetGroup.value?.id ? result.reference : -1;
                    });

                    loadScreenForceTargetGroups();
                });
        };

        const loadScreenForceTargetGroups = (): void => {
            data.isLoadingTargetGroups = true;

            actions.targetGroup
                .listScreenForce({
                    order: {
                        description: "asc",
                    },
                    filter: {
                        country: {
                            operator: "=",
                            value: targetGroup.value?.country ?? Country.NETHERLANDS,
                        },
                    },
                    pagination: {
                        page: 1,
                        per_page: 1000,
                    },
                })
                .then((targetGroups: DataResult<ScreenForceTargetGroup[]>) => {
                    data.screenForceTargetGroups =
                        targetGroups.data?.filter(
                            (targetGroup) =>
                                !data.usedScreenForceTargetGroups.includes(targetGroup.id)
                        ) ?? [];

                    data.isLoadingTargetGroups = false;
                })
                .catch(() => (data.isLoadingTargetGroups = false));
        };

        const save = (): void => {
            if (!targetGroup.value) {
                return;
            }

            if (!targetGroup.value?.sizes) {
                targetGroup.value.sizes = null;
            }

            actions.targetGroup
                .store(targetGroup.value)
                .then(() => {
                    ElMessage({
                        message:
                            (targetGroup.value?.name ?? targetGroup.value?.imported_name) +
                            " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                            (targetGroup.value?.name ?? targetGroup.value?.imported_name),
                        type: "error",
                    });
                });
        };

        const onCountrySelected = (): void => {
            loadTargetGroups();
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        watch(
            () => props.targetGroup,
            (value, oldValue) => {
                if (value !== oldValue) {
                    targetGroup.value = value ?? ({ country: Country.NETHERLANDS } as TargetGroup);
                }

                init();
            }
        );

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            addSize,
            deleteSize,
            dialogClosed,
            onCountrySelected,
            save,
            submitOnEnter,
            targetGroup,
            years,
        };
    },
});
