
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, Ref, ref } from "vue";

import TargetGroupApiService from "@/service/api/settings/target-group/target-group";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { TargetGroup } from "@/types/uzs/settings/target-group/target-group";
import TargetGroupDialog from "@/views/settings/target-groups/dialogs/TargetGroup.vue";
import UpdateYearDialog from "@/views/settings/target-groups/dialogs/UpdateYear.vue";

type CurrentRow = {
    row: TargetGroup;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { UpdateYearDialog, TargetGroupDialog },
    setup() {
        const targetGroupApiService = new TargetGroupApiService();
        const currentTargetGroup = ref(null) as Ref<TargetGroup | null>;
        const textCopier = new Copy();

        return {
            currentTargetGroup,
            targetGroupApiService,
            textCopier,
        };
    },
    computed: {
        page(): number {
            return this.pagination.page as number;
        },
        pages(): number {
            return this.targetGroups?.pagination?.total_pages ?? 1;
        },
        perPage(): number {
            return this.pagination.per_page as number;
        },
    },
    data() {
        return {
            filter: {} as FilterRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                name: "asc",
            } as OrderRequest,
            currentPage: undefined as number | undefined,
            loading: true,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            showFilterModal: false,
            showTargetGroupDialog: false,
            showUpdateYearDialog: false,
            sortingEnabled: false,
            targetGroups: {} as DataResult<TargetGroup[] | null>,
        };
    },
    mounted() {
        this.update();
    },
    methods: {
        delete(value: CurrentRow) {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                this.targetGroupApiService
                    .delete(value.row.id)
                    .then((result) => {
                        this.update();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        },
        getRowClassName(value: CurrentRow) {
            return "row " + value.row.id;
        },
        goToPage(page: number) {
            this.currentPage = page;
            this.pagination.page = page;

            this.update();
        },
        newTargetGroup() {
            this.currentTargetGroup = {} as TargetGroup;
            this.showTargetGroupDialog = true;
        },
        updateYear() {
            this.showUpdateYearDialog = true;
        },
        pageSizeChange(pageSize: number) {
            this.pagination.per_page = pageSize;

            this.update();
        },
        routeToDetails($event: Event, row: TargetGroup) {
            $event.stopImmediatePropagation();

            this.$router.push({
                name: "target-group-detail",
                params: {
                    id: row.id,
                },
            });
        },
        sortingChanged($event: SortEvent) {
            const property = $event.prop?.length ? $event.prop : null;
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!property || !this.targetGroups || !this.sortingEnabled) {
                return;
            }

            this.order = {
                [property]: direction,
            };

            this.update();
        },
        openTargetGroupDialog(targetGroup?: TargetGroup | null) {
            this.currentTargetGroup = targetGroup ?? null;
            this.showTargetGroupDialog = true;
        },
        targetGroupDialogInput(value: boolean) {
            this.showTargetGroupDialog = value;
            this.update();
        },
        updateYearDialogInput(value: boolean) {
            this.showUpdateYearDialog = value;
            this.update();
        },
        update() {
            this.loading = true;
            this.targetGroupApiService
                .fetchAll({
                    filter: this.filter,
                    pagination: this.pagination,
                    sort: this.order,
                })
                .then((response) => {
                    this.loading = false;
                    this.sortingEnabled = true;
                    this.targetGroups = response;
                });
        },
    },
});
