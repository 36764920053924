
import { ElMessage } from "element-plus";
import { range } from "lodash";
import { defineComponent, PropType, reactive, toRefs } from "vue";

import TargetGroupApiService from "@/service/api/settings/target-group/target-group";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
};

export default defineComponent({
    name: "UpdateYearDialog",
    props,
    setup(props, { emit }) {
        const targetGroupApiService = new TargetGroupApiService();
        const years = range(new Date().getFullYear() - 1, new Date().getFullYear() + 2);

        const actions = {
            targetGroup: {
                updateYear: (year: number) => targetGroupApiService.updateYear(year),
            },
        };

        const data = reactive({
            year: new Date().getFullYear() as number,
        });

        const close = (): void => {
            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            actions.targetGroup
                .updateYear(data.year)
                .then(() => {
                    ElMessage({
                        message:
                            "Jaar " +
                            data.year +
                            " is ingesteld als huidig jaar voor doelgroepgroottes.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message: "Er is een fout opgetreden bij het opslaan.",
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        return {
            ...toRefs(data),
            dialogClosed,
            save,
            submitOnEnter,
            years,
        };
    },
});
