<template>
    <el-dialog
        class="dialog-new-advertiser"
        :title="
            'Adverteerder - ' + (this.advertiser?.name !== undefined ? this.advertiser.name : '')
        "
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="isModalVisible"
        width="100%"
        ref="dialog"
    >
        <el-form :model="this.advertiser" label-position="top" @keydown="this.submitOnEnter">
            <el-form-item class="form-item-name" label="Naam">
                <el-input class="input-name" clearable v-model="this.advertiser.name" />
            </el-form-item>
            <el-form-item label="SEA Branded" v-if="this.advertiser.id">
                <i v-if="this.isLoadingTrafficSources" class="el-icon-loading"></i>
                <el-select
                    v-if="this.trafficSources?.length"
                    v-model="this.selectedTrafficSourceNames"
                    autocomplete="on"
                    filterable
                    multiple
                >
                    <el-option
                        v-for="trafficSource in this.trafficSources"
                        :key="trafficSource.id"
                        :label="trafficSource.name"
                        :value="trafficSource.name"
                        >{{ trafficSource.name }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="Website conversies">
                <el-select
                    class="select-events"
                    v-model="this.gaEvents"
                    autocomplete="on"
                    allow-create
                    clearable
                    default-first-option
                    filterable
                    multiple
                >
                    <el-option
                        class="select-event menu-item"
                        v-for="event in this.gaEvents"
                        :key="event"
                        :label="event"
                        :value="event"
                        >{{ event }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="Notes">
                <el-input type="textarea" rows="5" v-model="this.advertiser.notes" />
            </el-form-item>
            <el-form-item>
                <el-button class="button-save" type="primary" @click="this.save()"
                    >Opslaan</el-button
                >
                <el-button
                    v-if="this.advertiser?.id"
                    class="button-delete"
                    type="danger"
                    @click="this.deleteAdvertiser()"
                    >Verwijderen</el-button
                >
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs } from "vue";

import AdvertiserApiService from "@/service/api/advertiser";
import TrafficSourceApiService from "@/service/api/integration/bigquery/trafficSource";
import { Advertiser } from "@/types/uzs/advertiser";
import { TrafficSource } from "@/types/uzs/big-query-traffic-source/trafficSource";
import { SeaCampaign } from "@/types/uzs/campaign-advertiser/seaCampaign";

const props = {
    advertiser: {
        type: Object as PropType<Advertiser>,
        required: true,
    },
};

export default defineComponent({
    name: "AdvertiserDialog",
    props,
    setup(props, { emit }) {
        const advertiser = ref(props.advertiser ?? {}) as Ref<Advertiser | undefined>;
        const advertiserApi = new AdvertiserApiService();

        const data = reactive({
            gaEvents: [] as string[],
            trafficSources: null as Array<TrafficSource> | null,
            selectedTrafficSourceNames: [] as Array<string>,
            isModalVisible: true,
            isLoadingTrafficSources: false,
        });

        const close = (changed = false): void => {
            advertiser.value = {} as Advertiser;
            data.isModalVisible = false;

            emit("close", changed);
        };

        const deleteAdvertiser = (): void => {
            if (!advertiser.value?.id) {
                return;
            }

            const advertiserId = advertiser.value.id;

            ElMessageBox.confirm(
                "Weet je zeker dat je " + advertiser.value.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                advertiserApi
                    .delete(advertiserId)
                    .then(() => {
                        ElMessage({
                            message: advertiser?.value?.name + " is verwijderd.",
                            type: "success",
                        });

                        close();
                    })
                    .catch(() => {
                        ElMessage({
                            message:
                                "Er is een fout opgetreden bij het verwijderen van " +
                                advertiser?.value?.name,
                            type: "error",
                        });
                    });
            });
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            if (!advertiser?.value) {
                return;
            }

            advertiser.value.ga_events = data.gaEvents.join(',');
            advertiser.value.sea_campaigns = [];
            advertiser.value.sea_campaigns = data.selectedTrafficSourceNames.map((name) => {
                return { name } as SeaCampaign;
            });

            advertiserApi
                .store(advertiser.value)
                .then(() => {
                    ElMessage({
                        message: "Adverteerder " + advertiser?.value?.name + " is opgeslagen.",
                        type: "success",
                    });

                    close(true);
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                            advertiser?.value?.name,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        function findTrafficSources(): void {
            if (!advertiser.value?.id) {
                return;
            }

            data.isLoadingTrafficSources = true;

            const trafficSourceApi = new TrafficSourceApiService();

            trafficSourceApi
                .list(advertiser.value.id, "cpc")
                .then((response) => (data.trafficSources = response.data as Array<TrafficSource>))
                .then(() => (data.isLoadingTrafficSources = false));
        }

        onMounted(() => {
            if (props.advertiser) {
                advertiser.value = props.advertiser as Advertiser;
            }

            data.selectedTrafficSourceNames = [];

            if (advertiser.value?.ga_events) {
                if (advertiser.value.ga_events.indexOf(',') !== -1) {
                    data.gaEvents = advertiser.value.ga_events.split(',');
                } else {
                    data.gaEvents = [advertiser.value.ga_events];
                }
            }

            advertiser.value?.sea_campaigns?.forEach((seaCampaign) => {
                data.selectedTrafficSourceNames.push(seaCampaign.name);
            });

            findTrafficSources();
        });

        return {
            ...toRefs(data),
            close,
            advertiser,
            deleteAdvertiser,
            dialogClosed,
            save,
            submitOnEnter,
        };
    },
});
</script>
