import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { DataSetCampaign } from "@/types/uzs/settings/bigquery/dataSet";

export default class DataSetCampaignApiService {
    public async list(campaignReference: string): Promise<DataResult<DataSetCampaign[]>> {
        const response = await axios.get(
            config.backend + "/api/settings/bigquery/campaign-datasets/" + campaignReference
        );
        return response?.data as DataResult<DataSetCampaign[]>;
    }

    public async updateCampaign(
        campaignReference: string,
        datasets: string[]
    ): Promise<SuccessResult> {
        const response = await axios.post(
            config.backend + "/api/settings/bigquery/campaign-dataset",
            {
                campaign_reference: campaignReference,
                datasets,
            }
        );

        return response?.data as SuccessResult;
    }
}
