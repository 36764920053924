<template>
    <el-dialog
        title="Stel huidig jaar voor zender marktaandelen in"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        width="100%"
        ref="dialog"
    >
        <el-form label-position="top" @keydown="this.submitOnEnter">
            <el-form-item label="Jaar">
                <el-select v-model="this.year">
                    <el-option
                        v-for="year in this.years"
                        :key="year"
                        :label="year"
                        :value="year"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="this.save()">Opslaan</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElMessage } from "element-plus";
import { range } from "lodash";
import { defineComponent, PropType, reactive, toRefs } from "vue";

import ChannelApiService from "@/service/api/settings/channel/channel";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
};

export default defineComponent({
    name: "UpdateYearDialog",
    props,
    setup(props, { emit }) {
        const channelApiService = new ChannelApiService();
        const years = range(new Date().getFullYear() - 1, new Date().getFullYear() + 2);

        const actions = {
            channel: {
                updateYear: (year: number) => channelApiService.updateYear(year),
            },
        };

        const data = reactive({
            year: new Date().getFullYear() as number,
        });

        const close = (): void => {
            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            actions.channel
                .updateYear(data.year)
                .then(() => {
                    ElMessage({
                        message:
                            "Jaar " +
                            data.year +
                            " is ingesteld als huidig jaar voor marktaandelen van zenders.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message: "Er is een fout opgetreden bij het opslaan.",
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        return {
            ...toRefs(data),
            dialogClosed,
            save,
            submitOnEnter,
            years,
        };
    },
});
</script>
