import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { Export } from "@/types/uzs/bigquery/export";

export default class ExportApiService {
    public async list(): Promise<DataResult<Array<Export>>> {
        const response = await axios.get(config.backend + "/api/integration/bigquery-export/");
        return response?.data as DataResult<Array<Export>>;
    }

    public async download(uuid: string): Promise<string> {
        const response = await axios.post(
            config.backend + "/api/integration/bigquery-export/" + uuid
        );

        return window.URL.createObjectURL(new Blob([response.data]));
    }
}
