
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs } from "vue";
import { Store, useStore } from "vuex";

import SessionApiService from "@/service/api/integration/bigquery/session";
import DataSetApiService from "@/service/api/settings/bigquery/dataSet";
import DataSetCampaignApiService from "@/service/api/settings/bigquery/dataSetCampaign";
import { FlightState } from "@/types/state/flight";
import { RootState } from "@/types/state/root";
import { Flight } from "@/types/uzs/flight";
import { DataSet, DataSetCampaign } from "@/types/uzs/settings/bigquery/dataSet";
import { SuccessResult } from "@/types/result/successResult";

const props = {
    flight: {
        type: Object as PropType<Flight>,
        required: true,
    },
};

export default defineComponent({
    name: "PropertyDialog",
    props,
    setup(props, { emit }) {
        const flight = ref(props.flight ?? {}) as Ref<Flight | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.flight as FlightState;
        const dataSetApi = new DataSetApiService();
        const dataSetCampaignApi = new DataSetCampaignApiService();
        const sessionApi = new SessionApiService();
        const targets = ["BRAND UPLIFT", "WEBSITE TRAFFIC", "LEADS", "SALES"];

        const data = reactive({
            dates: [] as string[],
            datesAfterStartDate: [] as string[],
            dataSets: [] as DataSet[],
            selectedDataSets: [] as string[],
            paths: [] as string[],
            gaEvents: [] as string[],
            isModalVisible: true,
            isLoadingDates: false,
            isLoadingSessions: false,
            selectedStartDate: "",
            selectedEndDate: "",
            exportFilteredData: false,
        });

        const init = (): void => {
            let params = {};

            if (flight.value?.page_path) {
                if (flight.value.page_path.indexOf(",") > 0) {
                    data.paths = flight.value.page_path.split(",");
                } else if (flight.value.page_path !== "") {
                    data.paths = [flight.value.page_path];
                }
            }

            if (flight.value?.ga_events) {
                if (flight.value?.ga_events?.indexOf(",") > 0) {
                    data.gaEvents = flight.value.ga_events.split(",");
                } else if (flight.value.ga_events !== "") {
                    data.gaEvents = [flight.value.ga_events];
                }
            }

            if (flight.value?.country) {
                params = {
                    filter: {
                        active: 1,
                    },
                };
            }

            if (flight.value?.uuid) {
                dataSetCampaignApi
                    .list(flight.value.uuid)
                    .then((response) => {
                        const items = response.data as DataSetCampaign[];
                        data.selectedDataSets = items.map((item: DataSetCampaign) => item.dataset_reference);
                    });
            }

            dataSetApi
                .list(params)
                .then((response) => (data.dataSets = response.data as DataSet[]));

            if (!flight.value?.id) {
                return;
            }

            data.isLoadingDates = true;

            sessionApi.dates(flight.value?.id ?? 0).then((response) => {
                data.dates = response.data as string[];

                let datesAfterStartDate = [...data.dates];

                if (data.dates.length > 1) {
                    datesAfterStartDate.shift();
                }

                data.datesAfterStartDate = datesAfterStartDate;
                data.isLoadingDates = false;
            });
        };

        const clear = (): void => {
            if (!flight?.value) {
                return;
            }
        };

        const close = (changed = false): void => {
            data.isModalVisible = false;
            flight.value = {} as Flight;

            emit("close", changed);
        };

        const startDateSelected = (): void => {
            if (data.dates.length <= 1) {
                return;
            }

            data.datesAfterStartDate = data.dates.filter(
                (date: string) => date > data.selectedStartDate
            );
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const empty = (): void => {
            if (!flight?.value) {
                return;
            }

            ElMessageBox.confirm("Weet je zeker dat je de koppeling wilt legen?", "Legen?", {
                confirmButtonText: "Ja",
                cancelButtonText: "Nee",
                type: "warning",
            }).then(() => clear());
        };

        const nullOnEmpty = (): void => {
            if (flight.value && !flight.value?.uzs_property_id?.toString().length) {
                flight.value.uzs_property_id = null;
            }
        };

        const exportSessions = async (): Promise<void> => {
            if (!flight?.value?.id) {
                return;
            }

            data.isLoadingSessions = true;

            const result = await sessionApi.export(
                flight.value.id,
                data.selectedStartDate,
                data.selectedEndDate,
                data.exportFilteredData
            );

            if (result.success) {
                ElMessage({
                    message: "Export is succesvol gepland.",
                    type: "success",
                });
            } else {
                ElMessage({
                    message: "Export kon niet worden gepland.",
                    type: "error",
                });
            }

            data.isLoadingSessions = false;
        };

        const save = (): void => {
            if (!flight?.value?.uuid) {
                return;
            }

            flight.value.page_path = data.paths.join(",");
            flight.value.ga_events = data.gaEvents.join(",");

            dataSetCampaignApi.updateCampaign(flight.value.uuid, data.selectedDataSets).then((response: SuccessResult) => {
                if (!response.success) {
                    throw new Error();
                }

                if (!flight?.value?.id) {
                    return;
                }

                dataSetApi
                    .updateCampaign(
                        flight.value.id,
                        flight.value?.page_path ?? "",
                        flight.value?.ga_events ?? "",
                        flight.value?.include_homepage_in_paths ?? false
                    )
                    .then(() => {
                        ElMessage({
                            message: "BigQuery dataset voor " + flight?.value?.name + " is opgeslagen.",
                            type: "success",
                        });

                        close(true);
                    })
                    .catch(() => {
                        ElMessage({
                            message:
                                "Er is een fout opgetreden bij het opslaan van de BigQuery dataset voor " +
                                flight?.value?.name,
                            type: "error",
                        });
                    });
            }).catch(() => {
                ElMessage({
                    message:
                        "Er is een fout opgetreden bij het opslaan van de BigQuery dataset voor " +
                        flight?.value?.name,
                    type: "error",
                });

                return;
            });

        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        onMounted(() => {
            if (props.flight) {
                flight.value = props.flight as Flight;
                init();
            }
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            empty,
            exportSessions,
            flight,
            dialogClosed,
            nullOnEmpty,
            save,
            startDateSelected,
            submitOnEnter,
            targets,
        };
    },
});
